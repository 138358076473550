<!--
 * @Author: faf
 * @desc: 设备状态分析
 * @Date: 2022-12-19 17:29:49
 * @LastEditors: lijing
 * @ LastEditTime:
-->
<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item label="道路">
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                  dataSource="1"
                ></a-park-select>
              </el-form-item>
              <el-form-item label="时间">
                <a-date-picker
                  ref="datePicker"
                  :selectkeys="selectkeys"
                  :YearShowHidden="selectkeys[3]"
                  :isPreviousday="isPreviousday"
                ></a-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <!-- <el-button
          :loading="loading"
          type="primary"
          icon="el-icon-upload2"
          @click="exportHandler"
          >{{ $t('button.export') }}</el-button
        > -->
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
              <el-button type="info" icon="el-icon-delete" @click="resetForm()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
              <!-- v-if="$route.meta.authority.button.export" -->
            </div>
          </div>
        </el-form>
      </div>
      <div>
        <el-row :gutter="16">
          <el-col :span="12">
            <div class="chart-size">
              <graphTitle :title="'路内道路设备在线率'" class="paddingSize"> </graphTitle>
              <depot-equipment :type="1" :chartParam="dataObj"></depot-equipment>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chart-size">
              <graphTitle :title="'路内道路设备离线情况'" class="paddingSize"> </graphTitle>
              <depot-equipment1 :type="2" :chartParam="dataObj1"></depot-equipment1>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="tableWrapper bgFFF paddingB10">
        <div class="top bgFFF paddingLR20 header-wrapper">
          <graphTitle :title="'路内道路设备明细'"></graphTitle>
        </div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="areaName" :label="$t('searchModule.region')" align="center" fixed> </el-table-column>
          <el-table-column prop="parkName" :label="$t('searchModule.Road_name')" align="center" fixed> </el-table-column>

          <el-table-column
            :label="item.name"
            align="center"
            v-for="(item, i) in equipmentHeader"
            :key="i"
          >
            <el-table-column prop="equipmentCount" label="设备总数" align="center">
              <template slot-scope="scope">
                <div v-for="(val, index) in scope.row.parkEquipmentInfoVOList" :key="index">
                  {{ item.name == val.equipmentTypeName ? val.equipmentCount : "" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="equipmentOfflineNumber" label="离线次数" align="center">
              <template slot-scope="scope">
                <div v-for="(val, index) in scope.row.parkEquipmentInfoVOList" :key="index">
                  {{ item.name == val.equipmentTypeName ? val.equipmentOfflineNumber : "" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="equipmentOfflineDuration" label="离线总时长" align="center">
              <template slot-scope="scope">
                <div v-for="(val, index) in scope.row.parkEquipmentInfoVOList" :key="index">
                  {{
                    item.name == val.equipmentTypeName
                      ? parseInt(val.equipmentOfflineDuration / 60)
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="onlineRate" label="设备在线率" align="center">
              <template slot-scope="scope">
                <div v-for="(val, index) in scope.row.parkEquipmentInfoVOList" :key="index">
                  {{ item.name == val.equipmentTypeName ? val.onlineRate + "%" : "" }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, formatWeekDate } from "@/common/js/public.js";
import graphTitle from "@/components/graphTitle";
import depotEquipment from "./depotEquipment.vue";
import depotEquipment1 from "./depotEquipment1.vue";
/* eslint-disable */
export default {
  name: "equipmentStatusAnalysis",
  data() {
    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    const endTime = new Date();
    endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      selectkeys: ["date", "month", "week", "year"],
      dataObj: {
        legendData: [],
        unit: "",
        xData: [],
        yData: [],
        barData: [],
        barData1: [],
      },
      dataObj1: {
        legendData: [],
        unit: "",
        xData: [],
        barData: [],
        barData1: [],
      },
      isPreviousday: true,
      defaultTime: [startTime, endTime],
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      // 计费类型
      billingTypes: [],
      loading: false,
      orderType: 2,
      formInline: {
        // 区域
        areaId: "",
        // 区域
        streetId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
        // 计费类型
        scopeId: "",
        startTime: "",
        endTime: "",
        dateType: "day",
      },
      tableData: [],
      equipmentHeader: [
        { name: "高位视频" },
        { name: "地磁" },
        { name: "视频桩" },
        { name: "路牙机" },
      ],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "auto"
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "auto",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "auto",
        },
        {
          prop: "datasource",
          label: this.$t("list.Type_of_parking_lot"),
          width: "auto",
        },
      ],
      total: 0,
      pageNum: 1,
      pageSize: 15,
    };
  },
  components: {
    graphTitle,
    depotEquipment,
    depotEquipment1,
  },
  watch: {},
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },

    arraySpanMethod(val) {
      if (val.order == "ascending") {
        this.orderType = 1;
      } else {
        this.orderType = 2;
      }
      this.searchData();
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },

    // 清空
    resetForm() {
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.scopeId = "";
      this.formInline.dateType = "day";
      this.resetCarPark();
      this.$refs.cascader.clear();
      // this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      // this.$refs.parkTypeSelect.clear();
      this.parkClear();
      this.searchData();
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // 计费类型
    billingFn() {
      this.$axios.get("/acb/2.0/systems/loginUser/initScopeList").then((res) => {
        this.billingTypes = res.value.scopeList;
      });
    },

    // 查询夜间停车列表
    searchData() {
      this.loading = true;
      this.getEquipmentOnlineList();
      this.getEquipmentOfflineList();
      this.getEquipmentList();
    },

    getDateType() {
      let dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      let val = "";
      switch (dateType) {
        case 1:
          val = "day";
          break;
        case 5:
          val = "week";
          break;
        case 2:
          val = "month";
          break;
        case 3:
          val = "year";
          break;
        default:
          break;
      }
      return val;
    },
    // 导出
    exportHandler() {
      const tempData = {
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        scopeId: this.formInline.scopeId,
        parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        orderType: this.orderType,
      };
      const url = "/acb/2.0/parkIncomeAnalysis/parkPayList/export";
      exportExcelNew(url, tempData, "post");
    },

    /**
     * 设备在线比率
     */
    getEquipmentOnlineList() {
      this.$axios
        .get("/acb/2.0/equipmentAnalysis/getEquipmentOnlineRate", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            beginTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let equipmentTypeNameList = [];
            let onlineRateList = [];
            let offlineDurationList = [];
            let onlineDurationList = [];
            res.value &&
              res.value.forEach((el) => {
                equipmentTypeNameList.push(el.equipmentTypeName);
                onlineRateList.push(el.onlineRate * 100);
                offlineDurationList.push(parseInt(el.offlineDuration / 60));
                onlineDurationList.push(parseInt(el.onlineDuration / 60));
              });
            this.dataObj = {
              legendData: ["设备在线率", "设备离线时长", "设备在线时长"],
              unit: "时长：（分钟）",
              xData: equipmentTypeNameList,
              yData: onlineRateList,
              barData: offlineDurationList,
              barData1: onlineDurationList,
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 路内车场设备离线情况
     */
    getEquipmentOfflineList() {
      this.$axios
        .get("/acb/2.0/equipmentAnalysis/getEquipmentOfflineNumber", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            // dataSource: 1,
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            beginTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let equipmentTypeNameList = [];
            let offlineDurationList = [];
            let onlineDurationList = [];
            res.value &&
              res.value.forEach((el) => {
                equipmentTypeNameList.push(el.equipmentTypeName);
                offlineDurationList.push(parseInt(el.offlineNumber));
                onlineDurationList.push(parseInt(el.offlineDuration / 60));
              });
            this.dataObj1 = {
              legendData: ["离线次数", "离线时长"],
              unit: "次数：（次）",
              xData: equipmentTypeNameList,
              barData: offlineDurationList,
              barData1: onlineDurationList,
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 路内车场设备明细
     */
    getEquipmentList() {
      this.$axios
        .get("/acb/2.0/equipmentAnalysis/inRoadParkEquipment", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            // dataSource: 1,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            beginTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1 || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getArea();
  },
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.tableWrapper {
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 10px;
  }

  .table-content {
    /deep/ thead {
      width: 100%;
      height: 54px;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px 4px 0px 0px;

      th {
        background-color: #F3F7FF;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        font-size: 14px;
      }
    }
  }
}

.pagerWrapper {
  background: #fff;
  padding: 20px 10px 20px 0;
  margin-top: 0;
}

.chart-size {
  margin: 16px 0;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
}
</style>
