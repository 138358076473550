<!--
 * @Author: faf
 * @desc: 车场设备
 * @Date: 2022-07-07 11:21:02
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="content-wrapper">
    <div :id="type" class="chart"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    type: {
      default: "c1"
    },
    chartParam: {
      default: {}
    }
  },
  watch: {
    chartParam: {
      handler(newVal, oldVal) {
        this.drawGraph();
      },
      deep: true
    }
  },
  data() {
    return {};
  },
  methods: {
    drawGraph() {
      let option = {
        title: {
          text: this.chartParam.unit,
          color: "#9AA4B3",
          left: "26px",
          top: "20px",
          textStyle: {
            fontSize: 12,
            fontWeight: "400",
            color: "#9AA4B3"
          }
        },
        grid: {
          left: "30px",
          right: "30px",
          bottom: "0px",
          top: "57px",
          containLabel: true
        },
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          padding: 15,
          margin: [30, 20],
          textStyle: {
            color: "#7a828a",
            fontSize: 12
          },
          formatter: function (val) {
            let tip = `${val[0].axisValue}<br/>`;
            val.forEach((params) => {
              if (params.seriesIndex == 0) {
                tip += `${params.marker} ${params.seriesName}: ${parseInt(params.value)}%<br/>`;
              }
              if (params.seriesIndex == 1) {
                tip += `${params.marker} ${params.seriesName}: ${params.value}<br/>`;
              }
              if (params.seriesIndex == 2) {
                tip += `${params.marker} ${params.seriesName}: ${params.value}`;
              }
            });
            return tip;
          }
        },
        legend: {
          itemWidth: 16,
          itemHeight: 5,
          data: this.chartParam.legendData,
          right: "16px",
          top: "1%",
          textStyle: {
            color: "#9AA4B3"
          }
        },
        xAxis: {
          type: "category",
          data: this.chartParam.xData,
          splitLine: { show: false },
          axisTick: { show: false },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#9AA4B3"
            }
          },
          axisLabel: {
            margin: 16
          }
        },
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false
            },
            splitNumber: 5,
            interval: Math.ceil(Math.ceil(Math.max(...this.chartParam.barData1)) / 5),
            max: Math.ceil(Math.ceil(Math.max(...this.chartParam.barData1)) / 1),
            axisLabel: {
              inside: true,
              color: "#9AA4B3",
              margin: 0,
              padding: [0, 0, 2, 0],
              align: "left",
              verticalAlign: "bottom",
              formatter: function (value, index) {
                return "{title|" + value + "}";
              },
              rich: {
                title: {
                  fontSize: 12
                }
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#9AA4B3"
              }
            }
          },
          {
            type: "value",
            name: "设备在线率",
            max: 100,
            min: 0,
            nameGap: 20,
            axisTick: {
              show: false
            },
            axisLabel: {
              inside: true,
              color: "#9AA4B3",
              margin: 0,
              padding: [0, 0, 2, 0],
              align: "right",
              verticalAlign: "bottom",
              formatter: function (value, index) {
                return value + "%";
              },
              rich: {
                title: {
                  fontSize: 12
                }
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#9AA4B3"
              }
            }
          }
        ],
        series: [
          {
            name: this.chartParam.legendData[0],
            type: "line",
            smooth: true, // 缓折线
            yAxisIndex: 1,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 6,
            itemStyle: {
              color: "rgba(64, 217, 176, 0.6600)"
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(64, 217, 176, 0.2600)"
                  },
                  {
                    offset: 0.35,
                    color: "rgba(64, 217, 176, 0.1600)"
                  },
                  {
                    offset: 1,
                    color: "rgba(64, 217, 176,0)"
                  }
                ]
              }
            },
            data: this.chartParam.yData
          },
          {
            name: this.chartParam.legendData[1],
            type: "bar",
            barWidth: 10,
            left: "10px",
            itemStyle: {
              borderRadius: 2,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#5A90FF" },
                { offset: 1, color: "#5A90FF" }
              ])
            },
            data: this.chartParam.barData
          },
          {
            name: this.chartParam.legendData[2],
            type: "bar",
            barWidth: 10,
            left: "10px",
            itemStyle: {
              borderRadius: 2,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#FF7E08" },
                { offset: 1, color: "#FF7E08" }
              ])
            },
            data: this.chartParam.barData1
          }
        ]
      };
      let chart;
      chart = this.$echarts.init(document.getElementById(this.type));
      chart.setOption(option);
      window.addEventListener("resize", () => {
        chart && chart.resize();
      });
    }
  },
  mounted() {
    this.drawGraph();
    // console.log("init:::", this.chartParam.legendData);
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content-wrapper {
  width: 100%;
  height: 344px;
}

.chart {
  width: 100%;
  height: 344px;
}
</style>